/* src/pages/Help.css */
.help {
    text-align: center;
  }
  
  .help-hero {
    background: url('../assets/Banner.png') no-repeat center center;
    background-size: cover;
    width: 100%;
    padding-bottom:50px;
    max-width: 1280px;
    height: 230px;
  }
  
  .hero h1 {
    font-size: 3rem;
    margin: 0;
  }
  
  .hero p {
    font-size: 1.5rem;
  }
  
  .container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  .help-content {
    padding: 2rem 0;
  }
  
  .help-section {
    margin-bottom: 2rem;
  }
  
  .help-section h2 {
    font-size: 2rem;
    margin-bottom: 0.5rem;
  }
  
  .help-section p {
    font-size: 1.1rem;
  }
  
  /* Responsive styles */
  @media (max-width: 768px) {
    .help-hero {
      background: url('../assets/Banner3.png') no-repeat center center;
      background-size: cover;
      width: 100%;
      padding-bottom:50px;
      max-width: 768px;
      height: 53px;
      padding-left: 32px;
    }
  
    .help-section h2 {
      font-size: 1.5rem;
    }
  
    .help-section p {
      font-size: 1rem;
    }
  }
  