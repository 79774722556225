/* src/App.css */

.App {
  font-family: "Open Sans" Arial, sans-serif;
  margin: auto 0;
}

.navbar {
  background-color: #F2F2F2;
  padding: 1rem 0;
  position: relative;
  color: #333;
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.brand {
  color: #5498f0;
  font-weight: bold;
  text-decoration: none;
}

.nav-links {
  display: flex;
  gap: 1rem;
  align-items: center;
  list-style: none;
  margin-left: auto;
}

.nav-links li a {
  color: #333;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
}

.nav-links li a:hover {
  background-color: #ddd;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  margin-left: auto;
}

.hamburger .bar {
  width: 25px;
  height: 3px;
  background-color: #333;
  margin: 4px 0;
}

.nav-links-mobile {
  display: none;
  flex-direction: column;
  background-color: #333;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 1rem 0;
}

.nav-links-mobile li {
  margin: 1rem 0;
}

.nav-links-mobile li a {
  color: white;
  text-decoration: none;
}

.nav-links-mobile li a:hover {
  background-color: #444;
}
.btn-area{
  margin: 20px ;
  border-radius: 8px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-links {
    display: none;
  }

  .hamburger {
    display: flex;
    padding-right: 25px;
  }

  .nav-links-mobile {
    display: none;
  }

  .nav-links-mobile.open {
    display: flex;
  }
  .nav-links li a {
    color: #fff; /* Corrected color */
    text-decoration: none !important;
    padding-left: 20px;
  }
  .nav-links-mobile li {
    margin: 1rem 0;
    padding-left: 20px;
    text-decoration: none;
}
}

.main-content {
  max-width: 1280px;
  margin: 0 auto;
  width: 100%;
}

.footer-content {
  text-align: center;
  background: #333;
  color: white;
  width: 100%;
}

.footer-content .container {
  max-width: 1280px;
  margin: 0 auto;
}

footer {
  background: #333;
  color: #fff;
  text-align: center;
  padding: 1rem;
  bottom: 0;
  width: 100%;
  height: 60px; /* גובה קבוע לפוטר */
}

