/* src/styles/Chatbot.css */
.chatbot-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
}

.chatbot-toggle {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.chatbot {
  width: 300px;
  max-width: 100%;
  height: 400px;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.chat-window {
  flex-grow: 1;
  padding: 10px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.message {
  padding: 10px;
  margin: 5px 0;
  border-radius: 5px;
}

.message.user {
  background-color: #e1ffc7;
  align-self: flex-end;
}

.message.bot {
  background-color: #f1f1f1;
  align-self: flex-start;
}

.input-area {
  display: flex;
  border-top: 1px solid #ccc;
}

.input-area input {
  flex-grow: 1;
  padding: 10px;
  border: none;
  border-radius: 0 0 0 10px;
}

.input-area button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 0 0 10px 0;
}
