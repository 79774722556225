/* src/components/Glossary.css */
.glossary-container {
    max-width: 1280px;
    margin: 0 auto;
    padding: 2rem;
  }
  
  .glossary-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 2rem;
  }
  
  .glossary-table th, .glossary-table td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: left;
  }
  
  .glossary-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .glossary-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .term {
    font-weight: bold;
    width: 30%;
  }
  
  .description {
    width: 70%;
  }
  